<template>
	<div class="home">
		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(image, index) in imgList" :key="index">
				<img class="shop-image" :src="imageUrl+image.image" />
			</van-swipe-item>
		</van-swipe>
		<van-grid class="grid" :border="false" column-num="4">
			<van-grid-item @click="toUrl('/product/list')">
				<van-image class="grid-image" :src="require('@/assets/product.png')" />
				<div class="grid-title">商品管理</div>
			</van-grid-item>
			<van-grid-item @click="toUrl('/cate/list')">
				<van-image class="grid-image" :src="require('@/assets/cate.png')" />
				<div class="grid-title">分类管理</div>
			</van-grid-item>
			<van-grid-item @click="toUrl('/order/list')">
				<van-image class="grid-image" :src="require('@/assets/order.png')" />
				<div class="grid-title">订单管理</div>
			</van-grid-item>
			<van-grid-item @click="toUrl('/Discount')">
				<van-image class="grid-image" :src="require('@/assets/water.png')" />
				<div class="grid-title">消费券</div>
			</van-grid-item>
			<!-- <van-grid-item @click="toUrl('/water/list')">
				<van-image class="grid-image" :src="require('@/assets/water.png')" />
				<div class="grid-title">流水管理</div>
			</van-grid-item> -->
			<!-- <van-grid-item @click="toUrl('/line/list')">
				<van-image class="grid-image" :src="require('@/assets/lineup.png')" />
				<div class="grid-title">排队</div>
			</van-grid-item> -->
			<!-- <van-grid-item @click="toUrl('/make/list')">
				<van-image class="grid-image" :src="require('@/assets/time.png')" />
				<div class="grid-title">预约</div>
			</van-grid-item> -->
		</van-grid>
		<van-list :finished="true" finished-text="没有更多了">
			<div class="card">
				<div v-for="(item,index) in goodsList" :key="index" @click="details(item.id)">
					<van-card
						:thumb="imageUrl+item.image">
						<template #title>
							<div class="card-title van-multi-ellipsis--l2">{{item.title}}</div>
						</template>
						<template #desc>
							<div class="card-desc">
								<van-button size="mini"  v-for="(items,ins) in item.label">{{items}}</van-button>
								<!-- <van-button size="mini">新手指南</van-button> -->
							</div>
						</template>
						<template #price>
							<div class="card-price">
								<!-- <span>河南银联分部</span> - -->
								<span>{{item.page_view}}浏览</span>
							</div>
						</template>
					</van-card>
					<van-divider class="card-divider" v-show="index < card.length-1" />
				</div>
			</div>
		</van-list>
	</div>
</template>
<script>
	import {
		index
	} from '@/api'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		lunbotu,
		articleList
	} from '@/api/index'
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				current: 0,
				imageUrl: http.imageUrl,
				imgList: '',
				swipe: [{
						image: 'https://imgcps.jd.com/ling4/69612246180/5rSB6Z2i5YWo5Z-O54Ot5Yqo/54iG5qy-6ZKc5oOg/p-5c130ba282acdd181d949682/956de230/cr_1125x445_0_171/s1125x690/q70.jpg'
					},
					{
						image: 'https://m.360buyimg.com/mobilecms/s700x280_jfs/t1/155857/27/11174/157220/6037468eE64ce0ba7/b88e05ca814a2865.jpg!cr_1125x445_0_171!q70.jpg.dpg'
					}
				],
				card: [{}, {}, {}, {}, {}],
				page: 1, // 页码
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				newAri:[]
			}
		},
		mounted() {
			this.load()
			this.lunbotu()
			this.artiList()
		},
		methods: {
			lunbotu() {
				lunbotu().then((res) => {
					// console.log(res)
					this.imgList = res.data.data
				})
			},
			load() {
				const that = this;
				// index().then((res)=>{
				//   const { banners, menus, pops } = res.data
				//   that.swipes = banners
				//   that.grids = menus
				//   that.pops = pops
				// })
				
			},
			artiList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.page = this.page
				articleList(params).then((res) => {
					console.log(res)
					const rows = res.data.data
					const newAri = res.data.label
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			details(id) {
				this.$router.push({
					path: '/home/details',
					query: {
						id: id
					}
				})
			},
			toUrl(path, param = null) {
				toUrl(path, param)
			},
			onChange(current) {
				this.current = current
			}
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.home {
		min-height: calc(100vh);

		.custom-indicator {
			position: absolute;
			right: 5px;
			bottom: 5px;
			padding: 2px 5px;
			font-size: 12px;
			background: rgba(0, 0, 0, 0.1);
		}
		

		.grid,
		.card {
			margin: 1rem .5rem;
			border-radius: .5rem;
			overflow: hidden;
			width: 95%;
			background-color: #FFFFFF;
		}
		

		.card {
			background: white;
		}

		.grid-image {
			width: 2rem;
			height: 2rem;
		}

		.grid-title {
			font-size: 12px;
			color: #646566;
			padding-top: 5px;
		}

		.card-desc {
			padding-top: .3rem;
		}

		.card-title {
			font-weight: bold;
			font-size:0.8rem;
		}

		.card-price {
			color: gray;
		}

		.card-divider {
			margin: 5px 20px;
		}
	}

	.van-swipe {
		height: 200px;
	}
</style>
